<template>
  <b-card title="Cập nhật: Thông tin giới thiệu">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nội dung"
            label-cols-md="2"
            label-for="bannerData-title"
          >
            <b-form-input
              id="bannerData-title"
              v-model="pageInfo.banner_text"
              placeholder="Trung tâm Nghiên cứu và Phát triển Rau hoa quả là đơn vị sự nghiệp... "
              :class="{ 'is-invalid' : isInvalid(pageInfo.banner_text)}"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Ảnh giới thiệu"
            label-cols-md="2"
            label-for="bannerData-banner"
          >
            <b-form-input
              id="bannerData-banner"
              v-model="pageInfo.banner_url"
              placeholder="https://rhq.vn/logo.png"
              :class="{ 'is-invalid' : isInvalid(pageInfo.banner_url)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" v-if="pageInfo.banner_url">
          <b-form-group
              label="Xem trước hình ảnh"
              label-cols-md="2"
              label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="pageInfo.banner_url" v-viewer />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            type="submit"
            variant="primary"
          >
            Lưu thông tin
          </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput, BImg,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";
export default {
  name: "pageInfo.vue",
  directives: {
    Ripple
  },
  data() {
    return {
      isValidate: false,
      pageInfo: {
        id: 1998,
        banner_text: '',
        banner_url: '',
      },
    }
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BImg
  },
  created() {
    this.init()
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    handleSubmit () {
      this.isValidate = true
      if (
          this.pageInfo.banner_text.length &&
          this.pageInfo.banner_url.length)
      {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    async handleRequest() {
      this.$http.put(`/pageinfos/1998`, this.pageInfo)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    init() {
      this.$http.get(`/pageinfos/1998`)
      .then((res) => {
        this.pageInfo = res.data.data
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
            variant: 'danger',
          },
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
